




import { Component, Vue, Watch } from 'vue-property-decorator';
import FlowViewer from './flow-viewer.vue';

@Component({
  name: 'FlowDone',
  components: { FlowViewer }
})
export default class FlowStart extends Vue {
  operation: string = 'task_cc';
  get _taskDataId() {
    return this.$route.query.id;
  }
}
